import { combineReducers } from "redux";

import adminReducer from "./adminReducer";
import stageReducer from "./stageReducer";
import assignmentOrderReducer from "./assignmentOrderReducer";
import emailReducer from "./emailReducer";
import messageReducer from "./messageReducer";
import userReducer from "./userReducer";
import translationOrderReducer from "./translationOrderReducer";
import translationEmailReducer from "./translationEmailReducer";
import notificationReducer from "./notificationReducer";
import paymentLinkReducer from "./paymentLinkReducer";
import expertReducer from "./expertReducer";

const rootReducer = combineReducers({
    admin: adminReducer,
    stage: stageReducer,
    assignmentorder: assignmentOrderReducer,
    translationorder: translationOrderReducer,
    email: emailReducer,
    translationemail: translationEmailReducer,
    message: messageReducer,
    user: userReducer,
    notification: notificationReducer,
    paymentlinks: paymentLinkReducer,
    expert: expertReducer
});

export default rootReducer;