import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { getTranslationOrderNotificationsList } from "../../redux/actions/notificationActions";
import TranslationNotificationTable from "../../components/Tables/TranslationNotificationTable";
import socket from "../../api/socket";

const TranslationNotifications = () => {
    document.title = `Translation Order Notifications`

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const currentPage = useSelector((state) => (state.notification.page));
    const totalPages = useSelector((state) => (state.notification.total__pages));

    const [searchQuery] = useSearchParams();
    const [page, setPage] = useState(currentPage || 1);
    const [seen, setSeen] = useState("");



    // Getting data from params
    useEffect(() => {
        const getDataFromSearchParams = () => {
            const currentSeen = searchQuery.get("seen");
            setSeen(currentSeen ? currentSeen : "");
        }

        getDataFromSearchParams();
    }, [searchQuery])



    // Fetching data with respect to filters
    useEffect(() => {
        const abortController = new AbortController();

        const fetchList = () => {
            dispatch(getTranslationOrderNotificationsList({ seen, page, signal: abortController.signal }));
        }

        fetchList();

        return () => {
            abortController.abort();
        }
    }, [seen, page])



    // Pagination controlls
    const handlePageChange = (prev) => {
        seen !== "" && navigate(`?seen=${seen}`);
        if (prev) setPage(page - 1);
        else setPage(page + 1);
    }




    // Constant on socket update
    useEffect(() => {
        socket.on("translationnotificationupdate", () => dispatch(getTranslationOrderNotificationsList({ seen, page })))
        return () => {
            socket.off("translationnotificationupdate")
        }
    }, [seen, page])



    return (
        <div className="p-4">
            <div className="flex items-center justify-between mb-4">
                <div className="inline-flex items-center gap-4">
                    <button onClick={() => navigate(-1)} className="w-8 h-8 rounded-full bg-gray-200 text-black inline-flex items-center justify-center">
                        <ArrowLeftIcon className="w-6 h-6" />
                    </button>
                    <h1 className="text-2xl font-bold text-black">Translation Notifications</h1>
                </div>
                <div className="inline-flex items-center gap-3">
                    <div className="inline-flex items-center gap-3">
                        <Link to="#" className={`p-2 ${seen === "" ? "bg-primary text-white hover:bg-primaryDark" : "bg-gray-200 text-black hover:bg-gray-300"} rounded-full border`}>All Notifications</Link>
                        <Link to="?seen=false" className={`p-2 ${seen === "false" ? "bg-primary text-white hover:bg-primaryDark" : "bg-gray-200 text-black hover:bg-gray-300"} block rounded-full border`}>Unread Notifications</Link>
                        <Link to="?seen=true" className={`p-2 ${seen === "true" ? "bg-primary text-white hover:bg-primaryDark" : "bg-gray-200 text-black hover:bg-gray-300"} block rounded-full border`}>Read Notifications</Link>
                    </div>
                    <div className="inline-flex items-center gap-3 border rounded-full p-1 ps-2">
                        <p className="text-sm font-medium text-black">Page {page} of {totalPages}</p>
                        <button onClick={() => handlePageChange(true)} className="p-2 rounded-full bg-gray-200 hover:bg-gray-300" disabled={page === 1}>
                            <ChevronLeftIcon className="w-4 h-4" />
                        </button>
                        <button onClick={() => handlePageChange()} className="p-2 rounded-full bg-gray-200 hover:bg-gray-300" disabled={page === totalPages}>
                            <ChevronRightIcon className="w-4 h-4" />
                        </button>
                    </div>
                </div>
            </div>
            <TranslationNotificationTable />
        </div>
    )
}

export default TranslationNotifications;