export default function Tooltip({ title, right, left, top }) {
    const getClassName = () => {
        const result = right ? "top-1/2 left-[110%] -translate-y-1/2 origin-left"
            : top ? "bottom-[110%] left-1/2 -translate-x-1/2 origin-bottom"
                : left ? "top-1/2 right-[110%] -translate-y-1/2 origin-right"
                    : "top-[110%] left-1/2 -translate-x-1/2 orign-top"
        return result;
    }
    return (
        <div className={`w-max absolute ${getClassName()} px-2 py-1 rounded-md bg-gray-200 scale-0 group-hover:scale-100 transition-all duration-200`}>
            <p className="text-[12px] font-medium text-blackLight">{title || "Add tooltip title"}</p>
        </div>
    )
}
