import actionTypes from "../actions/actionTypes";

const initialState = {
    links: [],
    status__message: null
}

export default function paymentLinkReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actionTypes.PAYMENT_LINK.ADD_PAYMENT_LINK_SUCCESS:
            return { ...state, status__message: actionTypes.PAYMENT_LINK.ADD_PAYMENT_LINK_SUCCESS }

        case actionTypes.PAYMENT_LINK.ADD_PAYMENT_LINK_FAILED:
            return { ...state, status__message: actionTypes.PAYMENT_LINK.ADD_PAYMENT_LINK_FAILED }

        case actionTypes.PAYMENT_LINK.EDIT_PAYMENT_LINK_SUCCESS:
            return { ...state, status__message: actionTypes.PAYMENT_LINK.EDIT_PAYMENT_LINK_SUCCESS }

        case actionTypes.PAYMENT_LINK.EDIT_PAYMENT_LINK_FAILED:
            return { ...state, status__message: actionTypes.PAYMENT_LINK.EDIT_PAYMENT_LINK_FAILED }

        case actionTypes.PAYMENT_LINK.GET_PAYMENT_LINKS_SUCCESS:
            return {
                ...state,
                links: payload,
                status__message: actionTypes.PAYMENT_LINK.GET_PAYMENT_LINKS_SUCCESS
            }

        case actionTypes.PAYMENT_LINK.GET_PAYMENT_LINKS_FAILED:
            return {
                ...state,
                links: [],
                status__message: actionTypes.PAYMENT_LINK.GET_PAYMENT_LINKS_FAILED
            }

        case actionTypes.PAYMENT_LINK.DELETE_PAYMENT_LINK_SUCCESS:
            return { ...state, status__message: actionTypes.PAYMENT_LINK.DELETE_PAYMENT_LINK_SUCCESS }

        case actionTypes.PAYMENT_LINK.DELETE_PAYMENT_LINK_FAILED:
            return { ...state, status__message: actionTypes.PAYMENT_LINK.DELETE_PAYMENT_LINK_FAILED }

        default: return state;
    }
}