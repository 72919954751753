import actionTypes from "../actions/actionTypes";

const initialState = {
    logged__in: false,
    admin__details: null,
    subadmins: [],
    status__message: null
}


const adminReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.ADMIN.CHECK_LOGIN_STATUS:
            return { ...state, logged__in: payload }

        case actionTypes.ADMIN.ADMIN_SIGN_IN_SUCCESS:
            return {
                ...state,
                logged__in: true,
                status__message: actionTypes.ADMIN.ADMIN_SIGN_IN_SUCCESS
            }

        case actionTypes.ADMIN.ADMIN_SIGN_IN_FAILED:
            return {
                ...state,
                logged__in: false,
                status__message: actionTypes.ADMIN.ADMIN_SIGN_IN_FAILED
            }

        case actionTypes.ADMIN.ADMIN_SIGN_OUT:
            return {
                ...state,
                logged__in: false,
                admin__details: null,
                status__message: actionTypes.ADMIN.ADMIN_SIGN_OUT
            }

        case actionTypes.ADMIN.REQUEST_TOKEN_SUCCESS:
            return { ...state, status__message: actionTypes.ADMIN.REQUEST_TOKEN_SUCCESS }

        case actionTypes.ADMIN.REQUEST_TOKEN_FAILED:
            return { ...state, status__message: actionTypes.ADMIN.REQUEST_TOKEN_FAILED }

        case actionTypes.ADMIN.RESET_PASSWORD_SUCCESS:
            return { ...state, status__message: actionTypes.ADMIN.RESET_PASSWORD_SUCCESS }

        case actionTypes.ADMIN.RESET_PASSWORD_FAILED:
            return { ...state, status__message: actionTypes.ADMIN.RESET_PASSWORD_FAILED }

        case actionTypes.ADMIN.ADMIN_DETAILS_SUCCESS:
            return {
                ...state,
                status__message: actionTypes.ADMIN.ADMIN_DETAILS_SUCCESS,
                admin__details: payload
            }

        case actionTypes.ADMIN.ADMIN_DETAILS_FAILED:
            return {
                ...state,
                status__message: actionTypes.ADMIN.ADMIN_DETAILS_FAILED,
                admin__details: null
            }

        case actionTypes.ADMIN.UPDATE_PASSWORD_SUCCESS:
            return { ...state, status__message: actionTypes.ADMIN.UPDATE_PASSWORD_SUCCESS }

        case actionTypes.ADMIN.UPDATE_PASSWORD_FAILED:
            return { ...state, status__message: actionTypes.ADMIN.UPDATE_PASSWORD_FAILED }

        case actionTypes.ADMIN.UPDATE_PROFILE_SUCCESS:
            return { ...state, status__message: actionTypes.ADMIN.UPDATE_PROFILE_SUCCESS }

        case actionTypes.ADMIN.UPDATE_PROFILE_FAILED:
            return { ...state, status__message: actionTypes.ADMIN.UPDATE_PROFILE_FAILED }

        case actionTypes.ADMIN.ADD_SUBADMIN_SUCCESS:
            return { ...state, status__message: actionTypes.ADMIN.ADD_SUBADMIN_SUCCESS }

        case actionTypes.ADMIN.ADD_SUBADMIN_FAILED:
            return { ...state, status__message: actionTypes.ADMIN.ADD_SUBADMIN_FAILED }

        case actionTypes.ADMIN.EDIT_SUBADMIN_SUCCESS:
            return { ...state, status__message: actionTypes.ADMIN.EDIT_SUBADMIN_SUCCESS }

        case actionTypes.ADMIN.EDIT_SUBADMIN_FAILED:
            return { ...state, status__message: actionTypes.ADMIN.EDIT_SUBADMIN_FAILED }

        case actionTypes.ADMIN.DELETE_SUBADMIN_SUCCESS:
            return { ...state, status__message: actionTypes.ADMIN.DELETE_SUBADMIN_SUCCESS }

        case actionTypes.ADMIN.DELETE_SUBADMIN_FAILED:
            return { ...state, status__message: actionTypes.ADMIN.DELETE_SUBADMIN_FAILED }

        case actionTypes.ADMIN.GET_SUBADMINS_SUCCESS:
            return {
                ...state,
                subadmins: payload,
                status__message: actionTypes.ADMIN.GET_SUBADMINS_SUCCESS
            }

        case actionTypes.ADMIN.GET_SUBADMINS_FAILED:
            return {
                ...state,
                subadmins: [],
                status__message: actionTypes.ADMIN.GET_SUBADMINS_FAILED
            }

        case actionTypes.CLEAR_STATUS_MESSAGE:
            return { ...state, status__message: null }

        default: return state;
    }
}

export default adminReducer;