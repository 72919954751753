const actionTypes = {
    ADMIN: {
        CHECK_LOGIN_STATUS: "CHECK_LOGIN_STATUS",

        ADMIN_SIGN_IN_SUCCESS: "ADMIN_SIGN_IN_SUCCESS",
        ADMIN_SIGN_IN_FAILED: "ADMIN_SIGN_IN_FAILED",

        ADMIN_SIGN_OUT: "ADMIN_SIGN_OUT",

        REQUEST_TOKEN_SUCCESS: "REQUEST_TOKEN_SUCCESS",
        REQUEST_TOKEN_FAILED: "REQUEST_TOKEN_FAILED",
        RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
        RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",

        ADMIN_DETAILS_SUCCESS: "ADMIN_DETAILS_SUCCESS",
        ADMIN_DETAILS_FAILED: "ADMIN_DETAILS_FAILED",

        UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
        UPDATE_PASSWORD_FAILED: "UPDATE_PASSWORD_FAILED",

        UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
        UPDATE_PROFILE_FAILED: "UPDATE_PROFILE_FAILED",

        ADD_SUBADMIN_SUCCESS: "ADD_SUBADMIN_SUCCESS",
        ADD_SUBADMIN_FAILED: "ADD_SUBADMIN_FAILED",
        EDIT_SUBADMIN_SUCCESS: "EDIT_SUBADMIN_SUCCESS",
        EDIT_SUBADMIN_FAILED: "EDIT_SUBADMIN_FAILED",
        GET_SUBADMINS_SUCCESS: "GET_SUBADMINS_SUCCESS",
        GET_SUBADMINS_FAILED: "GET_SUBADMINS_FAILED",
        DELETE_SUBADMIN_SUCCESS: "DELETE_SUBADMIN_SUCCESS",
        DELETE_SUBADMIN_FAILED: "DELETE_SUBADMIN_FAILED",
    },

    STAGE: {
        ADD_STAGE_SUCCESS: "ADD_STAGE_SUCCESS",
        ADD_STAGE_FAILED: "ADD_STAGE_FAILED",

        GET_STAGES_SUCCESS: "GET_STAGES_SUCCESS",
        GET_STAGES_FAILED: "GET_STAGES_FAILED",

        EDIT_STAGE_FAILED: "EDIT_STAGE_FAILED",
        EDIT_STAGE_SUCCESS: "EDIT_STAGE_SUCCESS",

        DELETE_STAGE_SUCCESS: "DELETE_STAGE_SUCCESS",
        DELETE_STAGE_FAILED: "DELETE_STAGE_FAILED",
    },

    ORDER: {
        GET_ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
        GET_ORDERS_FAILED: "GET_ORDERS_FAILED",

        HANDLE_CHECKBOX: "HANDLE_CHECKBOX",

        SET_PRIORITY_SUCCESS: "SET_PRIORITY_SUCCESS",
        SET_PRIORITY_FAILED: "SET_PRIORITY_FAILED",

        DELETE_ORDER_SUCCESS: "DELETE_ORDER_SUCCESS",
        DELETE_ORDER_FAILED: "DELETE_ORDER_FAILED",

        READ_ORDER_SUCCESS: "READ_ORDER_SUCCESS",
        READ_ORDER_FAILED: "READ_ORDER_FAILED",

        ADD_ORDER_SUCCESS: "ADD_ORDER_SUCCESS",
        ADD_ORDER_FAILED: "ADD_ORDER_FAILED",
        EDIT_ORDER_SUCCESS: "EDIT_ORDER_SUCCESS",
        EDIT_ORDER_FAILED: "EDIT_ORDER_FAILED",

        GET_ASSIGNMENT_DETAILS_SUCCESS: "GET_ASSIGNMENT_DETAILS_SUCCESS",
        GET_ASSIGNMENT_DETAILS_FAILED: "GET_ASSIGNMENT_DETAILS_FAILED",
        REMOVE_ASSIGNMENT_DETAILS: "REMOVE_ASSIGNMENT_DETAILS",

        UPDATE_ORDER_STATUS_SUCCESS: "UPDATE_ORDER_STATUS_SUCCESS",
        UPDATE_ORDER_STATUS_FAILED: "UPDATE_ORDER_STATUS_FAILED",

        GET_TRANSLATION_DETAILS_SUCCESS: "GET_TRANSLATION_DETAILS_SUCCESS",
        GET_TRANSLATION_DETAILS_FAILED: "GET_TRANSLATION_DETAILS_FAILED",
        REMOVE_TRANSLATION_DETAILS: "REMOVE_TRANSLATION_DETAILS",

        SEARCH_ORDER_BY_ID_SUCCESS: "SEARCH_ORDER_BY_ID_SUCCESS",
        SEARCH_ORDER_BY_ID_FAILED: "SEARCH_ORDER_BY_ID_FAILED",
        REMOVE_SEARCH_ORDER_BY_ID: "REMOVE_SEARCH_ORDER_BY_ID",

        ORDER_TIMELINE_SUCCESS: "ORDER_TIMELINE_SUCCESS",
        ORDER_TIMELINE_FAILED: "ORDER_TIMELINE_FAILED",
        REMOVE_ORDER_TIMELINE: "REMOVE_ORDER_TIMELINE",

        ORDER_CALL_TIMELINE_SUCCESS: "ORDER_CALL_TIMELINE_SUCCESS",
        ORDER_CALL_TIMELINE_FAILED: "ORDER_CALL_TIMELINE_FAILED",
        REMOVE_ORDER_CALL_TIMELINE: "REMOVE_ORDER_CALL_TIMELINE",

        GET_SOLUTIONS_SUCCESS: "GET_SOLUTIONS_SUCCESS",
        GET_SOLUTIONS_FAILED: "GET_SOLUTIONS_FAILED",
        REMOVE_SOLUTIONS: "REMOVE_SOLUTIONS"
    },

    EMAIL: {
        ADD_TEMPLATE_SUCCESS: "ADD_TEMPLATE_SUCCESS",
        ADD_TEMPLATE_FAILED: "ADD_TEMPLATE_FAILED",

        EDIT_TEMPLATE_SUCCESS: "EDIT_TEMPLATE_SUCCESS",
        EDIT_TEMPLATE_FAILED: "EDIT_TEMPLATE_FAILED",

        GET_TEMPLATES_SUCCESS: "GET_TEMPLATES_SUCCESS",
        GET_TEMPLATES_FAILED: "GET_TEMPLATES_FAILED",

        TEMPLATE_DETAILS_SUCCESS: "TEMPLATE_DETAILS_SUCCESS",
        TEMPLATE_DETAILS_FAILED: "TEMPLATE_DETAILS_FAILED",
        REMOVE_TEMPLATE_DETAILS: "REMOVE_TEMPLATE_DETAILS",

        DELETE_TEMPLATE_SUCCESS: "DELETE_TEMPLATE_SUCCESS",
        DELETE_TEMPLATE_FAILED: "DELETE_TEMPLATE_FAILED",

        SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
        SEND_EMAIL_FAILED: "SEND_EMAIL_FAILED",
    },

    MESSAGE: {
        MESSAGE_LIST_SUCCESS: "MESSAGE_LIST_SUCCESS",
        MESSAGE_LIST_FAILED: "MESSAGE_LIST_FAILED",

        ROOM_MESSAGE_SUCCESS: "ROOM_MESSAGE_SUCCESS",
        ROOM_MESSAGE_FAILED: "ROOM_MESSAGE_FAILED",
        REMOVE_ROOM_MESSAGE: "REMOVE_ROOM_MESSAGE",

        MARK_MESSAGE_READ_SUCCESS: "MARK_MESSAGE_READ_SUCCESS",
        MARK_MESSAGE_READ_FAILED: "MARK_MESSAGE_READ_FAILED",

        EDIT_MESSAGE_SUCCESS: "EDIT_MESSAGE_SUCCESS",
        EDIT_MESSAGE_FAILED: "EDIT_MESSAGE_FAILED",

        DELETE_MESSAGE_SUCCESS: "DELETE_MESSAGE_SUCCESS",
        DELETE_MESSAGE_FAILED: "DELETE_MESSAGE_FAILED"
    },

    ORDER_NOTIFICATION: {
        HANDLE_NOTIFICATIONS_CHECKBOX: "HANDLE_NOTIFICATIONS_CHECKBOX",
        GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
        GET_NOTIFICATIONS_FAILED: "GET_NOTIFICATIONS_FAILED",
        GET_NOTIFICATIONS_LIST_SUCCESS: "GET_NOTIFICATIONS_LIST_SUCCESS",
        GET_NOTIFICATIONS_LIST_FAILED: "GET_NOTIFICATIONS_LIST_FAILED",
    },

    MESSAGE_NOTIFICATION: {
        GET_MESSAGE_NOTIFICATIONS_SUCCESS: "GET_MESSAGE_NOTIFICATIONS_SUCCESS",
        GET_MESSAGE_NOTIFICATIONS_FAILED: "GET_MESSAGE_NOTIFICATIONS_FAILED"
    },

    USER: {
        GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
        GET_USERS_FAILED: "GET_USERS_FAILED",

        GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
        GET_USER_DETAILS_FAILED: "GET_USER_DETAILS_FAILED",
        REMOVE_USER_DETAILS: "REMOVE_USER_DETAILS",
    },

    PAYMENT_LINK: {
        ADD_PAYMENT_LINK_SUCCESS: "ADD_PAYMENT_LINK_SUCCESS",
        ADD_PAYMENT_LINK_FAILED: "ADD_PAYMENT_LINK_FAILED",
        EDIT_PAYMENT_LINK_SUCCESS: "EDIT_PAYMENT_LINK_SUCCESS",
        EDIT_PAYMENT_LINK_FAILED: "EDIT_PAYMENT_LINK_FAILED",
        GET_PAYMENT_LINKS_SUCCESS: "GET_PAYMENT_LINKS_SUCCESS",
        GET_PAYMENT_LINKS_FAILED: "GET_PAYMENT_LINKS_FAILED",
        DELETE_PAYMENT_LINK_SUCCESS: "DELETE_PAYMENT_LINK_SUCCESS",
        DELETE_PAYMENT_LINK_FAILED: "DELETE_PAYMENT_LINK_FAILED",
    },

    EXPERT: {
        ADD_EXPERT_SUCCESS: "ADD_EXPERT_SUCCESS",
        ADD_EXPERT_FAILED: "ADD_EXPERT_FAILED",
        EDIT_EXPERT_SUCCESS: "EDIT_EXPERT_SUCCESS",
        EDIT_EXPERT_FAILED: "EDIT_EXPERT_FAILED",
        DELETE_EXPERT_SUCCESS: "DELETE_EXPERT_SUCCESS",
        DELETE_EXPERT_FAILED: "DELETE_EXPERT_FAILED",
        GET_EXPERTS_SUCCESS: "GET_EXPERTS_SUCCESS",
        GET_EXPERTS_FAILED: "GET_EXPERTS_FAILED"
    },

    CLEAR_STATUS_MESSAGE: "CLEAR_STATUS_MESSAGE"
}

export default actionTypes;