import actionTypes from "../actions/actionTypes";

const initialState = {
    created__id: null,

    templates: [],
    page: 1,
    pages: 1,
    selectrow: 25,

    template: null,

    status__message: null
}

const emailReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.EMAIL.ADD_TEMPLATE_SUCCESS:
            return {
                ...state,
                created__id: payload,
                status__message: actionTypes.EMAIL.ADD_TEMPLATE_SUCCESS
            }

        case actionTypes.EMAIL.ADD_TEMPLATE_FAILED:
            return {
                ...state,
                created__id: null,
                status__message: actionTypes.EMAIL.ADD_TEMPLATE_FAILED
            }

        case actionTypes.EMAIL.EDIT_TEMPLATE_SUCCESS:
            return {
                ...state,
                created__id: payload,
                status__message: actionTypes.EMAIL.EDIT_TEMPLATE_SUCCESS
            }

        case actionTypes.EMAIL.EDIT_TEMPLATE_FAILED:
            return {
                ...state,
                created__id: payload,
                status__message: actionTypes.EMAIL.EDIT_TEMPLATE_FAILED
            }

        case actionTypes.EMAIL.GET_TEMPLATES_SUCCESS:
            return {
                ...state,
                templates: payload.templates,
                page: payload.page,
                pages: payload.pages,
                selectrow: payload.selectrow,
                status__message: actionTypes.EMAIL.GET_TEMPLATES_SUCCESS
            }

        case actionTypes.EMAIL.GET_TEMPLATES_FAILED:
            return {
                ...state,
                templates: [],
                page: 1,
                pages: 1,
                selectrow: payload,
                status__message: actionTypes.EMAIL.GET_TEMPLATES_FAILED
            }

        case actionTypes.EMAIL.TEMPLATE_DETAILS_SUCCESS:
            return {
                ...state,
                template: payload,
                status__message: actionTypes.EMAIL.TEMPLATE_DETAILS_SUCCESS
            }

        case actionTypes.EMAIL.TEMPLATE_DETAILS_FAILED:
            return {
                ...state,
                template: null,
                status__message: actionTypes.EMAIL.TEMPLATE_DETAILS_FAILED
            }

        case actionTypes.EMAIL.REMOVE_TEMPLATE_DETAILS:
            return {
                ...state,
                template: null,
                status__message: actionTypes.EMAIL.REMOVE_TEMPLATE_DETAILS
            }

        case actionTypes.EMAIL.DELETE_TEMPLATE_SUCCESS:
            return {
                ...state,
                status__message: actionTypes.EMAIL.DELETE_TEMPLATE_SUCCESS
            }

        case actionTypes.EMAIL.DELETE_TEMPLATE_FAILED:
            return {
                ...state,
                status__message: actionTypes.EMAIL.DELETE_TEMPLATE_FAILED
            }

        case actionTypes.EMAIL.SEND_EMAIL_SUCCESS:
            return {
                ...state,
                status__message: actionTypes.EMAIL.SEND_EMAIL_SUCCESS
            }

        case actionTypes.EMAIL.SEND_EMAIL_FAILED:
            return {
                ...state,
                status__message: actionTypes.EMAIL.SEND_EMAIL_FAILED
            }

        case actionTypes.CLEAR_STATUS_MESSAGE:
            return { ...state, status__message: null }

        default: return state;
    }
}

export default emailReducer;