import React, { Suspense, lazy } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ArrowPathIcon } from "@heroicons/react/20/solid";

import { checkLoginStatus } from "./redux/actions/adminActions";
import TranslationNotifications from "./pages/Notifications/TranslationNotifications";
import AssignmentNotifications from "./pages/Notifications/AssignmentNotifications";

const Header = lazy(() => import("./components/Header/Header"));
const SignIn = lazy(() => import("./pages/SignIn/SignIn"));
const AssignmentDashboard = lazy(() =>
  import("./pages/Dashboard/AssignmentDashboard")
);
const NewAssignment = lazy(() => import("./pages/Orders/NewAssignment"));
const EditAssignment = lazy(() => import("./pages/Orders/EditAssignment"));
const AssignmentDetails = lazy(() =>
  import("./pages/Details/AssignmentDetails")
);
const StageManagement = lazy(() =>
  import("./pages/StageManagement/StageManagement")
);
const AssignmentEmail = lazy(() => import("./pages/Email/AssignmentEmail"));
const NewAssignmentEmail = lazy(() =>
  import("./pages/Email/NewAssignmentEmail")
);
const EditAssignmentEmail = lazy(() =>
  import("./pages/Email/EditAssignmentEmail")
);
const AssignmentConversation = lazy(() =>
  import("./pages/Conversations/AssignmentConversation")
);
const AssignmentUsers = lazy(() => import("./pages/Users/AssignmentUsers"));
const TranslationDashboard = lazy(() =>
  import("./pages/Dashboard/TranslationDashboard")
);
const NewTranslation = lazy(() => import("./pages/Orders/NewTranslation"));
const EditTranslation = lazy(() => import("./pages/Orders/EditTranslation"));
const TranslationDetails = lazy(() =>
  import("./pages/Details/TranslationDetails")
);
const TranslationStageManagement = lazy(() =>
  import("./pages/StageManagement/TranslationStageManagement")
);
const TranslationEmail = lazy(() => import("./pages/Email/TranslationEmail"));
const NewTranslationEmail = lazy(() =>
  import("./pages/Email/NewTranslationEmail")
);
const EditTranslationEmail = lazy(() =>
  import("./pages/Email/EditTranslationEmail")
);
const TranslationUsers = lazy(() => import("./pages/Users/TranslationUsers"));
const TranslationConversation = lazy(() =>
  import("./pages/Conversations/TranslationConversation")
);
const SubAdmins = lazy(() => import("./pages/SubAdmins"));
const PaymentLinks = lazy(() => import("./pages/PaymentLinks"));
const Experts = lazy(() => import("./pages/Experts"));
const OpenOrders = lazy(() => import("./pages/OpenOrders/OpenOrders"));
// Suspense fallback
const SuspenseFallback = () => {
  return (
    <div className="h-screen w-screen bg-transparent flex justify-center items-center">
      <ArrowPathIcon className="text-black w-8 h-8 animate-spin" />
    </div>
  );
};

function App() {
  const dispatch = useDispatch();

  // Check login status
  const loginStatus = useSelector((state) => state.admin.logged__in);

  React.useEffect(() => {
    dispatch(checkLoginStatus());
  }, []);

  // Request notifications permission
  React.useEffect(() => {
    const askPermission = async () => {
      await Notification.requestPermission();
    };

    setTimeout(() => {
      askPermission();
    }, 5000);
  }, []);

  // Authentication
  const Authentication = () => {
    const token = localStorage.getItem("_token");
    const role = localStorage.getItem("_role");

    return token && role === "admin" ? <Outlet /> : <Navigate to="/" />;
  };

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <BrowserRouter>
        {loginStatus && <Header />}
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/open-orders" element={<OpenOrders />} />
          <Route path="/assignments" element={<Authentication />}>
            <Route path="/assignments" element={<AssignmentDashboard />} />
          </Route>
          <Route path="/new-assignment" element={<Authentication />}>
            <Route path="/new-assignment" element={<NewAssignment />} />
          </Route>
          <Route path="/edit-assignment/:id" element={<Authentication />}>
            <Route path="/edit-assignment/:id" element={<EditAssignment />} />
          </Route>
          <Route
            path="/assignments/order-details/:id"
            element={<Authentication />}
          >
            <Route
              path="/assignments/order-details/:id"
              element={<AssignmentDetails />}
            />
          </Route>

          <Route path="/stage-management" element={<Authentication />}>
            <Route path="/stage-management" element={<StageManagement />} />
          </Route>

          <Route
            path="/assignment-email-templates"
            element={<Authentication />}
          >
            <Route
              path="/assignment-email-templates"
              element={<AssignmentEmail />}
            />
          </Route>
          <Route
            path="/new-assignment-email-template"
            element={<Authentication />}
          >
            <Route
              path="/new-assignment-email-template"
              element={<NewAssignmentEmail />}
            />
          </Route>
          <Route
            path="/edit-assignment-email-template/:id"
            element={<Authentication />}
          >
            <Route
              path="/edit-assignment-email-template/:id"
              element={<EditAssignmentEmail />}
            />
          </Route>

          <Route path="/assignment-conversations" element={<Authentication />}>
            <Route
              path="/assignment-conversations"
              element={<AssignmentConversation />}
            />
          </Route>
          <Route
            path="/assignment-conversations/:room"
            element={<Authentication />}
          >
            <Route
              path="/assignment-conversations/:room"
              element={<AssignmentConversation />}
            />
          </Route>

          <Route path="/assignment-users" element={<Authentication />}>
            <Route path="/assignment-users" element={<AssignmentUsers />} />
          </Route>

          <Route path="/assignment-notifications" element={<Authentication />}>
            <Route
              path="/assignment-notifications"
              element={<AssignmentNotifications />}
            />
          </Route>

          <Route path="/translations" element={<Authentication />}>
            <Route path="/translations" element={<TranslationDashboard />} />
          </Route>
          <Route path="/new-translation" element={<Authentication />}>
            <Route path="/new-translation" element={<NewTranslation />} />
          </Route>
          <Route path="/edit-translation/:id" element={<Authentication />}>
            <Route path="/edit-translation/:id" element={<EditTranslation />} />
          </Route>
          <Route
            path="/translations/order-details/:id"
            element={<Authentication />}
          >
            <Route
              path="/translations/order-details/:id"
              element={<TranslationDetails />}
            />
          </Route>

          <Route
            path="/translation-stage-management"
            element={<Authentication />}
          >
            <Route
              path="/translation-stage-management"
              element={<TranslationStageManagement />}
            />
          </Route>

          <Route
            path="/translation-email-templates"
            element={<Authentication />}
          >
            <Route
              path="/translation-email-templates"
              element={<TranslationEmail />}
            />
          </Route>
          <Route
            path="/new-translation-email-template"
            element={<Authentication />}
          >
            <Route
              path="/new-translation-email-template"
              element={<NewTranslationEmail />}
            />
          </Route>
          <Route
            path="/edit-translation-email-template/:id"
            element={<Authentication />}
          >
            <Route
              path="/edit-translation-email-template/:id"
              element={<EditTranslationEmail />}
            />
          </Route>

          <Route path="/translation-conversations" element={<Authentication />}>
            <Route
              path="/translation-conversations"
              element={<TranslationConversation />}
            />
          </Route>
          <Route
            path="/translation-conversations/:room"
            element={<Authentication />}
          >
            <Route
              path="/translation-conversations/:room"
              element={<TranslationConversation />}
            />
          </Route>

          <Route path="/translation-users" element={<Authentication />}>
            <Route path="/translation-users" element={<TranslationUsers />} />
          </Route>

          <Route path="/translation-notifications" element={<Authentication />}>
            <Route
              path="/translation-notifications"
              element={<TranslationNotifications />}
            />
          </Route>

          <Route path="/subadmins" element={<Authentication />}>
            <Route path="/subadmins" element={<SubAdmins />} />
          </Route>

          <Route path="/payment-links" element={<Authentication />}>
            <Route path="/payment-links" element={<PaymentLinks />} />
          </Route>
          <Route path="/experts" element={<Authentication />}>
            <Route path="/experts" element={<Experts />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
