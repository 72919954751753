import actionTypes from "../actions/actionTypes";

const initialState = {
    orders: [],
    page: 1,
    pages: 1,

    select__row: 25,
    website__code: "",
    deadline: "",
    translation__id: "",
    status: "",
    sub__status: "",
    counter__status: "",
    priority: "",

    selected__orders: [],

    created__id: null,

    translation: null,

    searched__order: [],

    order__timeline: [],
    order__call__timeline: [],

    solution: [],
    drafts: [],

    status__message: null
}

const translationOrderReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.ORDER.GET_ORDERS_SUCCESS:
            return {
                ...state,
                orders: payload.orders,
                page: payload.page,
                pages: payload.pages,

                select__row: payload.selectrow,
                website__code: payload.websitecode,
                deadline: payload.deadline,
                translation__id: payload.translationid,
                status: payload?.status,
                sub__status: payload?.substatus,
                counter__status: payload?.counterstatus,
                priority: payload?.priority,

                status__message: actionTypes.ORDER.GET_ORDERS_SUCCESS
            }

        case actionTypes.ORDER.GET_ORDERS_FAILED:
            return {
                ...state,
                orders: [],
                page: 1,
                pages: 1,

                select__row: 25,
                website__code: payload.websitecode,
                deadline: payload.deadline,
                translation__id: payload.translationid,
                status: payload?.status,
                sub__status: payload?.substatus,
                counter__status: payload?.counterstatus,
                priority: payload?.priority,

                status__message: actionTypes.ORDER.GET_ORDERS_FAILED
            }

        case actionTypes.ORDER.HANDLE_CHECKBOX:
            return {
                ...state,
                orders: payload.orders,
                selected__orders: payload.selected
            }

        case actionTypes.ORDER.SET_PRIORITY_SUCCESS:
            return { ...state, status__message: actionTypes.ORDER.SET_PRIORITY_SUCCESS }

        case actionTypes.ORDER.SET_PRIORITY_FAILED:
            return { ...state, status__message: actionTypes.ORDER.SET_PRIORITY_FAILED }

        case actionTypes.ORDER.DELETE_ORDER_SUCCESS:
            return { ...state, status__message: actionTypes.ORDER.DELETE_ORDER_SUCCESS }

        case actionTypes.ORDER.DELETE_ORDER_FAILED:
            return { ...state, status__message: actionTypes.ORDER.DELETE_ORDER_FAILED }

        case actionTypes.ORDER.READ_ORDER_SUCCESS:
            return { ...state, status__message: actionTypes.ORDER.READ_ORDER_SUCCESS }

        case actionTypes.ORDER.READ_ORDER_FAILED:
            return { ...state, status__message: actionTypes.ORDER.READ_ORDER_FAILED }

        case actionTypes.ORDER.ADD_ORDER_SUCCESS:
            return { ...state, status__message: actionTypes.ORDER.ADD_ORDER_SUCCESS, created__id: payload }

        case actionTypes.ORDER.ADD_ORDER_FAILED:
            return { ...state, status__message: actionTypes.ORDER.ADD_ORDER_FAILED, created__id: null }

        case actionTypes.ORDER.EDIT_ORDER_SUCCESS:
            return { ...state, status__message: actionTypes.ORDER.EDIT_ORDER_SUCCESS, created__id: payload }

        case actionTypes.ORDER.EDIT_ORDER_FAILED:
            return { ...state, status__message: actionTypes.ORDER.EDIT_ORDER_FAILED, created__id: null }

        case actionTypes.ORDER.GET_TRANSLATION_DETAILS_SUCCESS:
            return { ...state, status__message: actionTypes.ORDER.GET_TRANSLATION_DETAILS_SUCCESS, translation: payload }

        case actionTypes.ORDER.GET_TRANSLATION_DETAILS_FAILED:
            return { ...state, status__message: actionTypes.ORDER.GET_TRANSLATION_DETAILS_FAILED, translation: null }

        case actionTypes.ORDER.REMOVE_TRANSLATION_DETAILS:
            return { ...state, translation: null }

        case actionTypes.ORDER.UPDATE_ORDER_STATUS_SUCCESS:
            return { ...state, status__message: actionTypes.ORDER.UPDATE_ORDER_STATUS_SUCCESS }

        case actionTypes.ORDER.UPDATE_ORDER_STATUS_FAILED:
            return { ...state, status__message: actionTypes.ORDER.UPDATE_ORDER_STATUS_FAILED }

        case actionTypes.ORDER.SEARCH_ORDER_BY_ID_SUCCESS:
            return {
                ...state,
                searched__order: payload,
                status__message: actionTypes.ORDER.SEARCH_ORDER_BY_ID_SUCCESS
            }

        case actionTypes.ORDER.SEARCH_ORDER_BY_ID_FAILED:
            return {
                ...state,
                searched__order: [],
                status__message: actionTypes.ORDER.SEARCH_ORDER_BY_ID_FAILED
            }

        case actionTypes.ORDER.REMOVE_SEARCH_ORDER_BY_ID:
            return { ...state, searched__order: [] }

        case actionTypes.ORDER.ORDER_TIMELINE_SUCCESS:
            return {
                ...state,
                order__timeline: payload,
                status__message: actionTypes.ORDER.ORDER_TIMELINE_SUCCESS
            }

        case actionTypes.ORDER.ORDER_TIMELINE_FAILED:
            return {
                ...state,
                order__timeline: [],
                status__message: actionTypes.ORDER.ORDER_TIMELINE_FAILED
            }

        case actionTypes.ORDER.REMOVE_ORDER_TIMELINE:
            return {
                ...state,
                order__timeline: [],
                status__message: actionTypes.ORDER.REMOVE_ORDER_TIMELINE
            }

        case actionTypes.ORDER.ORDER_CALL_TIMELINE_SUCCESS:
            return {
                ...state,
                order__call__timeline: payload,
                status__message: actionTypes.ORDER.ORDER_CALL_TIMELINE_SUCCESS
            }

        case actionTypes.ORDER.ORDER_CALL_TIMELINE_FAILED:
            return {
                ...state,
                order__call__timeline: [],
                status__message: actionTypes.ORDER.ORDER_CALL_TIMELINE_FAILED
            }

        case actionTypes.ORDER.REMOVE_ORDER_CALL_TIMELINE:
            return {
                ...state,
                order__call__timeline: [],
                status__message: actionTypes.ORDER.REMOVE_ORDER_CALL_TIMELINE
            }

        case actionTypes.ORDER.GET_SOLUTIONS_SUCCESS:
            return {
                ...state,
                solution: payload.solution,
                drafts: payload.drafts,
                status__message: actionTypes.ORDER.GET_SOLUTIONS_SUCCESS
            }

        case actionTypes.ORDER.GET_SOLUTIONS_FAILED:
            return {
                ...state,
                solution: [],
                drafts: [],
                status__message: actionTypes.ORDER.GET_SOLUTIONS_FAILED
            }

        case actionTypes.ORDER.REMOVE_SOLUTIONS:
            return {
                ...state,
                solution: [],
                drafts: [],
            }

        case actionTypes.CLEAR_STATUS_MESSAGE:
            return { ...state, created__id: null, status__message: null }

        default: return state;
    }
}

export default translationOrderReducer;