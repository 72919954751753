import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ArrowPathIcon, TrashIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid"
import moment from "moment";

import styles from "./Table.module.css";
import { colorByWebsite } from "../../utils/colorByWebsite";

import actionTypes from "../../redux/actions/actionTypes";
import { getCountryCode } from "../../utils/getCountryCode";
import Tooltip from "../Tooltip/Tooltip";
import { deleteTranslationNotification, readTranslationNotification, unreadTranslationNotification } from "../../redux/actions/notificationActions";

export default function TranslationNotificationTable() {
    const dispatch = useDispatch();

    const orders = useSelector((state) => (state.notification.notifications__list));
    const statusMessage = useSelector((state) => (state.notification.status__message));

    const [loading, setLoading] = useState(true);

    React.useEffect(() => {
        if (statusMessage === actionTypes.ORDER_NOTIFICATION.GET_NOTIFICATIONS_LIST_SUCCESS || statusMessage === actionTypes.ORDER_NOTIFICATION.GET_NOTIFICATIONS_LIST_FAILED) setLoading(false);
    }, [statusMessage])



    return loading ? (
        <div className="h-[400px] w-full flex justify-center items-center">
            <ArrowPathIcon className="w-8 h-8 text-black animate-spin" />
        </div>
    ) : (
        <table className={`${styles.table} w-full`}>
            <thead>
                <tr className="border-b-[1px]">
                    <th>Client Name</th>
                    <th>Translation Id</th>
                    <th>Contact Info</th>
                    <th>Deadline</th>
                    <th>Arrived On</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {
                    orders?.length === 0 ? <tr>
                        <td>Notification table is empty!</td>
                    </tr> : orders?.map((item) => (
                        <tr key={item?._id} className={`border-b last:border-0 ${item?.seen ? "font-normal" : "font-bold"}`}>
                            <td>{item?.orderid?.name}</td>
                            <td>
                                <Link to={`/translations/order-details/${item?.orderid?._id}`} className="p-2 rounded-full" style={colorByWebsite(item?.orderid?.website_code)}>{item?.orderid?.translation_id}</Link>
                            </td>
                            <td>
                                <p className="font-medium">{item?.orderid?.email}</p>
                                <p>{getCountryCode(item?.orderid?.country)} {item?.orderid?.phone}</p>
                            </td>
                            <td>{moment(item?.orderid?.deadline).format("DD MMMM, YYYY")}</td>
                            <td>{moment(item?.createdAt).format("DD MMMM, YYYY")}</td>
                            <td>
                                <div className="inline-flex items-center gap-3">
                                    <button onClick={() => dispatch(deleteTranslationNotification(item?._id))} className="rounded-full bg-red-600 text-white p-2">
                                        <TrashIcon className="w-4" />
                                    </button>
                                    {
                                        item?.seen ? <button onClick={() => dispatch(unreadTranslationNotification(item?._id, item?.orderid?._id))} className="rounded-full bg-primary text-white p-2 relative group">
                                            <Tooltip title="Mark Unread" left />
                                            <EyeSlashIcon className="w-4" />
                                        </button> : <button onClick={() => dispatch(readTranslationNotification(item?._id, item?.orderid?._id))} className="rounded-full bg-green-700 text-white p-2 relative group">
                                            <Tooltip title="Mark Read" left />
                                            <EyeIcon className="w-4" />
                                        </button>
                                    }
                                </div>
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}
