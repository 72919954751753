export const colorByWebsite = (webcode) => {
    return webcode === "QSH" ? { backgroundColor: "#ff9c71", color: "#ffffff" }
        : webcode === "SAAH" ? { backgroundColor: "#007a4d", color: "#ffffff" }
            : webcode === "AMH" ? { backgroundColor: "#7a1129", color: "#ffffff" }
                : webcode === "CAH" ? { backgroundColor: "#e10407", color: "#ffffff" }
                    : webcode === "ANH" ? { backgroundColor: "#c8102e", color: "#ffffff" }
                        : webcode === "AZH" ? { backgroundColor: "#1c3971", color: "#ffffff" }
                            : webcode === "UAZH" ? { backgroundColor: "#621c56", color: "#ffffff" }
                                : webcode === "AUH" ? { backgroundColor: "#000000", color: "#ffffff" }
                                    : webcode === "SRL" ? { backgroundColor: "#eb7400", color: "#ffffff" }
                                        : webcode === "BMH" ? { backgroundColor: "#f70b0b", color: "#ffffff" }
                                            : webcode === "ASH" ? { backgroundColor: "#fcf611", color: "#000000" }
                                                : webcode === "AHM" ? { backgroundColor: "#f6d7b8", color: "#000000" }
                                                    : webcode === "MAH" ? { backgroundColor: "#e3431b", color: "#ffffff" }
                                                        : webcode === "AIH" ? { backgroundColor: "#ff8200", color: "#000000" }
                                                            : webcode === "SMAH" ? { backgroundColor: "#3b499e", color: "#ffffff" }
                                                                : webcode === "STS" ? { backgroundColor: "#d20001", color: "#ffffff" }
                                                                    : webcode === "MTS" ? { backgroundColor: "#b40639", color: "#ffffff" }
                                                                        : webcode === "GTH" ? { backgroundColor: "#fc9928", color: "#ffffff" }
                                                                            : webcode === "ICA" ? { backgroundColor: "#153855", color: "#ffffff" }
                                                                                : webcode === "MYTS" ? { backgroundColor: "#0052B4", color: "#ffffff" }
                                                                                    : { backgroundColor: "#ffffff", color: "#000000" }
}