import actionTypes from "../actions/actionTypes";

const initialState = {
    experts: [],
    status__message: null
}

export default function expertReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actionTypes.EXPERT.ADD_EXPERT_SUCCESS:
            return { ...state, status__message: actionTypes.EXPERT.ADD_EXPERT_SUCCESS }

        case actionTypes.EXPERT.ADD_EXPERT_FAILED:
            return { ...state, status__message: actionTypes.EXPERT.ADD_EXPERT_FAILED }

        case actionTypes.EXPERT.EDIT_EXPERT_SUCCESS:
            return { ...state, status__message: actionTypes.EXPERT.EDIT_EXPERT_SUCCESS }

        case actionTypes.EXPERT.EDIT_EXPERT_FAILED:
            return { ...state, status__message: actionTypes.EXPERT.EDIT_EXPERT_FAILED }

        case actionTypes.EXPERT.DELETE_EXPERT_SUCCESS:
            return { ...state, status__message: actionTypes.EXPERT.DELETE_EXPERT_SUCCESS }

        case actionTypes.EXPERT.DELETE_EXPERT_FAILED:
            return { ...state, status__message: actionTypes.EXPERT.DELETE_EXPERT_FAILED }

        case actionTypes.EXPERT.GET_EXPERTS_SUCCESS:
            return {
                ...state,
                experts: payload,
                status__message: actionTypes.EXPERT.GET_EXPERTS_SUCCESS
            }

        case actionTypes.EXPERT.GET_EXPERTS_FAILED:
            return {
                ...state,
                experts: [],
                status__message: actionTypes.EXPERT.GET_EXPERTS_FAILED
            }

        default: return state;
    }
}