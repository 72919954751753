import { toast } from "react-toastify";
import axios from "../../api/axios";
import actionTypes from "./actionTypes";

// Get assignment notifications
export const getAssignmentOrderNotifications = (signal) => async (dispatch) => {
  const token = localStorage.getItem("_token");

  await axios
    .get(`/api/assignmentnotification/search?page=1&selectrow=25&seen=false`, {
      signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response?.status === 200) {
        dispatch({
          type: actionTypes.ORDER_NOTIFICATION.GET_NOTIFICATIONS_SUCCESS,
          payload: {
            notifications: response?.data?.notifications,
            count: response?.data?.count,
          },
        });

        setTimeout(
          () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
          1000
        );
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.ORDER_NOTIFICATION.GET_NOTIFICATIONS_FAILED,
      });

      setTimeout(
        () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
        1000
      );
    });
};

// Get assignment notifications list
export const getAssignmentOrderNotificationsList =
  (data) => async (dispatch) => {
    const token = localStorage.getItem("_token");

    await axios
      .get(
        `/api/assignmentnotification/search?page=${
          data?.page || 1
        }&selectrow=50&seen=${data?.seen || ""}`,
        {
          signal: data?.signal,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: actionTypes.ORDER_NOTIFICATION.GET_NOTIFICATIONS_LIST_SUCCESS,
            payload: {
              notifications: response?.data?.notifications,
              seen: data?.seen || "",
              totalPages: response?.data?.totalPages,
            },
          });

          setTimeout(
            () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
            1000
          );
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.ORDER_NOTIFICATION.GET_NOTIFICATIONS_LIST_FAILED,
          payload: data?.seen || "",
        });

        setTimeout(
          () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
          1000
        );
      });
  };

// Delete assignment notifications
export const deleteAssignmentNotification = (id) => async () => {
  const token = localStorage.getItem("_token");

  await axios
    .delete(`/api/assignmentnotification/delete/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res?.status === 200) toast.success("Notification Deleted!");
    })
    .catch((error) => toast.success(error?.response?.data?.message));
};

// Read assignment notifications
export const readAssignmentNotification = (item) => async () => {
  const userid = localStorage.getItem("_id");
  const token = localStorage.getItem("_token");

  await axios
    .patch(
      `/api/assignmentnotification/markread`,
      {
        id: item?._id,
        order: item?.order?._id,
        userid,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      if (res?.status === 200) toast.success("Notifications read!");
    })
    .catch((error) => toast.success(error?.response?.data?.message));
};

export const handleNotificationsCheckbox = (data) => (dispatch) => {
  const { e, orders } = data;
  const { name, checked } = e.target;

  const newData = orders?.map((item) =>
    name === "SELECT_ALL"
      ? { ...item, select: checked }
      : item?._id === name
      ? { ...item, select: checked }
      : item
  );

  dispatch({
    type: actionTypes.ORDER_NOTIFICATION.HANDLE_NOTIFICATIONS_CHECKBOX,
    payload: {
      orders: newData,
      selected: newData?.filter((item) => item?.select === true),
    },
  });
};

// Read all assignment notifications
export const readUnreadAllAssignmentNotification = (orders) => async () => {
  const userid = localStorage.getItem("_id");
  const token = localStorage.getItem("_token");

  const readarray = orders?.filter((item) => item?.seen);
  const unreadarray = orders?.filter((item) => !item?.seen);

  const readOrders = unreadarray?.map((item) => ({
    id: item?._id,
    orderId: item?.order?._id,
  }));
  const unreadOrders = readarray?.map((item) => ({
    id: item?._id,
    orderId: item?.order?._id,
  }));

  if (readOrders?.length)
    await axios
      .patch(
        `/api/assignmentnotification/markallread`,
        {
          orders: readOrders,
          userid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.status === 200) toast.success("Notifications read!");
      })
      .catch((error) => toast.success(error?.response?.data?.message));

  if (unreadOrders?.length)
    await axios
      .patch(`/api/assignmentnotification/markallunread`, {
        orders: unreadOrders,
        userid,
      })
      .then((res) => {
        if (res?.status === 200) toast.success("Notifications unread!");
      })
      .catch((error) => toast.success(error?.response?.data?.message));
};

// Unread assignment notifications
export const unreadAssignmentNotification = (item) => async () => {
  const userid = localStorage.getItem("_id");
  const token = localStorage.getItem("_token");

  await axios
    .patch(
      `/api/assignmentnotification/markunread`,
      {
        id: item?._id,
        order: item?.order?._id,
        userid,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      if (res?.status === 200) toast.success("Notifications Unread!");
    })
    .catch((error) => toast.success(error?.response?.data?.message));
};

// Get assignment message notifications
export const getAssignmentMessageNotifications =
  (signal) => async (dispatch) => {
    const userid = localStorage.getItem("_id");
    const token = localStorage.getItem("_token");

    await axios
      .get(`/api/assignmentmessage/notification/${userid}`, {
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        let count = 0;
        res.data.array?.map((a) => (count = count + a.count));

        dispatch({
          type: actionTypes.MESSAGE_NOTIFICATION
            .GET_MESSAGE_NOTIFICATIONS_SUCCESS,
          payload: {
            notifications: res.data.array,
            count,
          },
        });

        setTimeout(() => {
          dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE });
        }, 1000);
      })
      .catch(() => {
        dispatch({
          type: actionTypes.MESSAGE_NOTIFICATION
            .GET_MESSAGE_NOTIFICATIONS_FAILED,
        });
        setTimeout(() => {
          dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE });
        }, 1000);
      });
  };

// Get translation notifications
export const getTranslationOrderNotifications =
  (signal) => async (dispatch) => {
    const token = localStorage.getItem("_token");

    await axios
      .get(
        `/api/translationnotification/search?page=1&selectrow=25&seen=false`,
        {
          signal,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: actionTypes.ORDER_NOTIFICATION.GET_NOTIFICATIONS_SUCCESS,
            payload: {
              notifications: response?.data?.notifications,
              count: response?.data?.count,
            },
          });

          setTimeout(
            () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
            1000
          );
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.ORDER_NOTIFICATION.GET_NOTIFICATIONS_FAILED,
        });

        setTimeout(
          () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
          1000
        );
      });
  };

// Get translation notifications list
export const getTranslationOrderNotificationsList =
  (data) => async (dispatch) => {
    const token = localStorage.getItem("_token");

    await axios
      .get(
        `/api/translationnotification/search?page=${
          data?.page || 1
        }&selectrow=50&seen=${data?.seen || ""}`,
        {
          signal: data?.signal,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: actionTypes.ORDER_NOTIFICATION.GET_NOTIFICATIONS_LIST_SUCCESS,
            payload: {
              notifications: response?.data?.notifications,
              seen: data?.seen || "",
              totalPages: response?.data?.totalPages,
            },
          });

          setTimeout(
            () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
            1000
          );
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.ORDER_NOTIFICATION.GET_NOTIFICATIONS_LIST_FAILED,
          payload: data?.seen || "",
        });

        setTimeout(
          () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
          1000
        );
      });
  };

// Delete translation notifications
export const deleteTranslationNotification = (id) => async () => {
  const token = localStorage.getItem("_token");

  await axios
    .delete(`/api/translationnotification/delete/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res?.status === 200) toast.success("Notification Deleted!");
    })
    .catch((error) => toast.success(error?.response?.data?.message));
};

// Read translation notifications
export const readTranslationNotification = (id, orderid) => async () => {
  const token = localStorage.getItem("_token");

  await axios
    .patch(
      `/api/translationnotification/markread/${id}`,
      { orderid },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      if (res?.status === 200) toast.success("Notifications read!");
    })
    .catch((error) => toast.success(error?.response?.data?.message));
};

// Unread translation notifications
export const unreadTranslationNotification = (id, orderid) => async () => {
  const token = localStorage.getItem("_token");

  await axios
    .patch(
      `/api/translationnotification/markunread/${id}`,
      { orderid },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      if (res?.status === 200) toast.success("Notifications Unread!");
    })
    .catch((error) => toast.success(error?.response?.data?.message));
};

// Get translation message notifications
export const getTranslationMessageNotifications =
  (signal) => async (dispatch) => {
    const userid = localStorage.getItem("_id");
    const token = localStorage.getItem("_token");

    await axios
      .get(`/api/translationmessage/notification/${userid}`, {
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        let count = 0;
        res.data.array?.map((a) => (count = count + a.count));

        dispatch({
          type: actionTypes.MESSAGE_NOTIFICATION
            .GET_MESSAGE_NOTIFICATIONS_SUCCESS,
          payload: {
            notifications: res.data.array,
            count,
          },
        });

        setTimeout(() => {
          dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE });
        }, 1000);
      })
      .catch(() => {
        dispatch({
          type: actionTypes.MESSAGE_NOTIFICATION
            .GET_MESSAGE_NOTIFICATIONS_FAILED,
        });
        setTimeout(() => {
          dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE });
        }, 1000);
      });
  };
