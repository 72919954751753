import actionTypes from "../actions/actionTypes";

const initialState = {
    stages: [],
    page: 1,
    pages: 1,
    select__row: 10,

    status__message: null
}

const stageReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.STAGE.ADD_STAGE_SUCCESS:
            return { ...state, status__message: actionTypes.STAGE.ADD_STAGE_SUCCESS }

        case actionTypes.STAGE.ADD_STAGE_FAILED:
            return { ...state, status__message: actionTypes.STAGE.ADD_STAGE_FAILED }

        case actionTypes.STAGE.GET_STAGES_SUCCESS:
            return {
                ...state,
                stages: payload.stages,
                page: payload.page,
                pages: payload.pages,
                select__row: payload.selectRow,
                status__message: actionTypes.STAGE.GET_STAGES_SUCCESS
            }

        case actionTypes.STAGE.GET_STAGES_FAILED:
            return {
                ...state,
                stages: [],
                page: 1,
                pages: 1,
                select__row: payload,
                status__message: actionTypes.STAGE.GET_STAGES_FAILED
            }

        case actionTypes.STAGE.EDIT_STAGE_SUCCESS:
            return { ...state, status__message: actionTypes.STAGE.EDIT_STAGE_SUCCESS }

        case actionTypes.STAGE.EDIT_STAGE_FAILED:
            return { ...state, status__message: actionTypes.STAGE.EDIT_STAGE_FAILED }

        case actionTypes.STAGE.DELETE_STAGE_SUCCESS:
            return { ...state, status__message: actionTypes.STAGE.DELETE_STAGE_SUCCESS }

        case actionTypes.STAGE.DELETE_STAGE_FAILED:
            return { ...state, status__message: actionTypes.STAGE.DELETE_STAGE_FAILED }

        default: return state;
    }
}

export default stageReducer;