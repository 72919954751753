import { toast } from "react-toastify";

import axios from "../../api/axios";
import actionTypes from "./actionTypes";

export const checkLoginStatus = () => ({
  type: actionTypes.ADMIN.CHECK_LOGIN_STATUS,
  payload:
    localStorage.getItem("_token") && localStorage.getItem("_role") === "admin"
      ? true
      : false,
});

export const adminSignIn = (data) => async (dispatch) => {
  await axios
    .post("/api/assignmentadmin/login", data)
    .then((response) => {
      if (response?.data?.admin?.role === "admin") {
        localStorage.setItem("_token", response?.data?.token);
        localStorage.setItem("_id", response?.data?.admin?._id);
        localStorage.setItem("_email", response?.data?.admin?.email);
        localStorage.setItem("_role", response?.data?.admin?.role);

        toast.success("Login credentials accepted!");
        dispatch({ type: actionTypes.ADMIN.ADMIN_SIGN_IN_SUCCESS });

        setTimeout(
          () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
          1000
        );
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      dispatch({ type: actionTypes.ADMIN.ADMIN_SIGN_IN_FAILED });

      setTimeout(
        () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
        1000
      );
    });
};

export const adminSignOut = () => (dispatch) => {
  localStorage.clear();
  dispatch({ type: actionTypes.ADMIN.ADMIN_SIGN_OUT });

  setTimeout(() => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }), 1000);
};

export const adminDetails = (signal) => async (dispatch) => {
  const id = localStorage.getItem("_id");
  const token = localStorage.getItem("_token");

  await axios
    .get(`/api/assignmentadmin/${id}`, {
      signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response?.data?.admin?.role === "admin") {
        dispatch({
          type: actionTypes.ADMIN.ADMIN_DETAILS_SUCCESS,
          payload: response?.data?.admin,
        });
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      dispatch({ type: actionTypes.ADMIN.ADMIN_DETAILS_FAILED });

      dispatch(adminSignOut());
    });
};

export const updatePassword = (data) => async (dispatch) => {
  const token = localStorage.getItem("_token");

  await axios
    .patch("/api/assignmentadmin/update/password/", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response?.status === 200) {
        toast.success("Password updated successfully!");
        dispatch({ type: actionTypes.ADMIN.UPDATE_PASSWORD_SUCCESS });

        setTimeout(
          () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
          1000
        );
      }
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      dispatch({ type: actionTypes.ADMIN.UPDATE_PASSWORD_FAILED });

      setTimeout(
        () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
        1000
      );
    });
};

export const updateProfile = (file) => async (dispatch) => {
  const token = localStorage.getItem("_token");

  const data = new FormData();
  data.append("profile_pic", file);

  await axios
    .patch("/api/assignmentadmin/update/profilePic", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        toast.success("Profile updated successfully!");
        dispatch({ type: actionTypes.ADMIN.UPDATE_PROFILE_SUCCESS });

        setTimeout(
          () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
          1000
        );
      }
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      dispatch({ type: actionTypes.ADMIN.UPDATE_PROFILE_FAILED });

      setTimeout(
        () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
        1000
      );
    });
};

export const addSubadmin = (data) => async (dispatch) => {
  const token = localStorage.getItem("_token");

  try {
    await axios.post("/api/assignmentadmin/signup", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({ type: actionTypes.ADMIN.ADD_SUBADMIN_SUCCESS });
    setTimeout(
      () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
      2000
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || error?.message);

    dispatch({ type: actionTypes.ADMIN.ADD_SUBADMIN_FAILED });
    setTimeout(
      () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
      2000
    );
  }
};

export const editSubadmin = (data) => async (dispatch) => {
  const token = localStorage.getItem("_token");

  try {
    await axios.patch(`/api/assignmentadmin/update/details`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({ type: actionTypes.ADMIN.EDIT_SUBADMIN_SUCCESS });
    setTimeout(
      () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
      2000
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || error?.message);

    dispatch({ type: actionTypes.ADMIN.EDIT_SUBADMIN_FAILED });
    setTimeout(
      () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
      2000
    );
  }
};

export const deleteSubadmin = (id) => async (dispatch) => {
  const token = localStorage.getItem("_token");

  try {
    await axios.delete(`/api/assignmentadmin/delete/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({ type: actionTypes.ADMIN.DELETE_SUBADMIN_SUCCESS });
    setTimeout(
      () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
      2000
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || error?.message);

    dispatch({ type: actionTypes.ADMIN.DELETE_SUBADMIN_FAILED });
    setTimeout(
      () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
      2000
    );
  }
};

export const getAllSubadmin = (signal) => async (dispatch) => {
  const token = localStorage.getItem("_token");

  try {
    const response = await axios.get(`/api/assignmentadmin/all`, {
      signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: actionTypes.ADMIN.GET_SUBADMINS_SUCCESS,
      payload: response?.data,
    });
    setTimeout(
      () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
      2000
    );
  } catch (error) {
    dispatch({ type: actionTypes.ADMIN.GET_SUBADMINS_FAILED });
    setTimeout(
      () => dispatch({ type: actionTypes.CLEAR_STATUS_MESSAGE }),
      2000
    );
  }
};
