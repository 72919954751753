import actionTypes from "../actions/actionTypes";

const initialState = {
    message__list: [],

    room: null,
    quantity: 2,
    room__message: [],

    status__message: null
}

const messageReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.MESSAGE.MESSAGE_LIST_SUCCESS:
            return {
                ...state,
                message__list: payload,
                status__message: actionTypes.MESSAGE.MESSAGE_LIST_SUCCESS
            }

        case actionTypes.MESSAGE.MESSAGE_LIST_FAILED:
            return {
                ...state,
                message__list: [],
                status__message: actionTypes.MESSAGE.MESSAGE_LIST_FAILED
            }

        case actionTypes.MESSAGE.ROOM_MESSAGE_SUCCESS:
            return {
                ...state,
                room__message: payload.roomMessage,
                room: payload.room,
                quantity: payload.quantity,
                status__message: actionTypes.MESSAGE.ROOM_MESSAGE_SUCCESS
            }

        case actionTypes.MESSAGE.ROOM_MESSAGE_FAILED:
            return {
                ...state,
                room__message: [],
                room: payload,
                status__message: actionTypes.MESSAGE.ROOM_MESSAGE_FAILED
            }

        case actionTypes.MESSAGE.REMOVE_ROOM_MESSAGE:
            return {
                ...state,
                room__message: [],
                room: null,
                quantity: 25,
            }

        case actionTypes.CLEAR_STATUS_MESSAGE:
            return { ...state, status__message: null }

        default: return state;
    }
}

export default messageReducer;